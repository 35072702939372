import * as moment from 'moment';
import 'moment-timezone';
import { unitOfTime } from 'moment';

/**
 * «Учитывая дату, вернуть новую дату со временем, установленным на полночь».
 *
 * Первая строка функции является комментарием. Комментарии игнорируются компилятором
 * @param {Date} date - Дата для преобразования в начало дня.
 * @returns Функция, которая принимает дату и возвращает дату.
 */
export function dateToBeginDay(date: Date): Date {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

/**
 * «Учитывая дату, вернуть новую дату со временем, установленным на конец дня».
 *
 * Первая строка функции является комментарием. Рекомендуется включать комментарии в код. Комментарии игнорируются
 * компилятором
 * @param {Date} date - Дата конвертации
 * @returns Объект даты со временем, установленным на конец дня.
 */
export function dateToEndDay(date: Date): Date {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
}

/**
 * Он преобразует дату в строку в формате, ожидаемом серверной частью.
 * @param {Date} date - Дата - дата, которую нужно преобразовать
 * @param {'original' | 'dayStart' | 'dayEnd'} [mode=original] - 'оригинал' | 'начало дня' | 'конец дня' = 'исходный'
 * @returns Строка
 */
export function dateToBackend(
  date: Date,
  mode: 'original' | 'dayStart' | 'dayEnd' = 'original'
): string {
  if (mode === 'dayStart') {
    dateToBeginDay(date);
  }
  if (mode === 'dayEnd') {
    dateToEndDay(date);
  }
  return moment(date).format('DD.MM.YYYY H:mm:ss');
}

/**
 * Он принимает дату и строку формата и возвращает отформатированную строку даты.
 * @param date - Дата для форматирования.
 * @param [format=DD.MM.YYYY] - Формат даты.
 * @returns Функция, которая принимает два аргумента: дату и формат.
 */
export function formatDate(date, format = 'DD.MM.YYYY') {
  return moment(date).format(format);
}

/**
 * Он принимает дату, часовой пояс и формат и возвращает отформатированную строку даты.
 * @param date - дата, которую вы хотите отформатировать
 * @param [timeZone=Europe/Moscow] - Часовой пояс, в который вы хотите преобразовать.
 * @param [format=DD.MM.YYYY] - Формат даты.
 */
export function formatTimeZoneDate(
  date,
  timeZone = 'Europe/Moscow',
  format = 'DD.MM.YYYY'
) {
  return moment(date).tz(timeZone).format(format);
}

/**
 * Он принимает дату и строку формата и возвращает отформатированную строку даты.
 * @param {Date} date - Дата для форматирования
 * @param {string} fomrat - Формат даты, которую вы хотите отобразить.
 * @returns Функция, которая принимает два аргумента, дату и формат, и возвращает строку.
 */
export function dateTransform(date: Date, fomrat: string) {
  return moment(date).format(fomrat);
}

/**
 * «Возвращает разницу между двумя датами в указанную единицу времени».
 *
 * Первые два параметра — это даты для сравнения. Третий параметр — это единица времени, используемая для сравнения.
 * Четвертый параметр — логическое значение, определяющее, должен ли результат округляться или нет.
 * @param {Date} date1 - Первая дата для сравнения.
 * @param {Date} date2 - Дата для сравнения. По умолчанию используется текущая дата.
 * @param [unitOfTime_days=days] - unitOfTime.Diff = 'дни'
 * @param [precise=true] - Если true, результатом будет число в указанной единице времени. Если false, результатом будет
 * число миллисекунд.
 * @returns Разница между двумя датами в указанную единицу времени.
 */
export function dateDiff(
  date1: Date,
  date2: Date = new Date(),
  unitOfTime_days: unitOfTime.Diff = 'days',
  precise = true
): number {
  return moment(date1).diff(moment(date2), unitOfTime_days, precise);
}

/**
 * Он принимает полное имя и возвращает короткое имя
 * @param {string} fullFio - строка - полное имя
 */
export function fullNameToShort(fullFio: string): string {
  const excludeShort = ['не назначена', 'не назначен'];
  if (fullFio) {
    if (excludeShort.indexOf(fullFio.toLocaleLowerCase()) !== -1) {
      return fullFio;
    }

    fullFio = fullFio.trim();
    const fioParts = fullFio.split(' ', 3);
    let shortFio = fioParts.length > 0 && fioParts[0];
    for (let i = 1; i < fioParts.length; i++) {
      shortFio += fioParts[i].length ? ` ${fioParts[i][0].toUpperCase()}.` : '';
    }
    return shortFio;
  }
  return null;
}

/**
 * Он принимает массив строк и символ и возвращает строку
 * @param fullFields - Массив строк, которые вы хотите преобразовать в одну строку.
 * @param {string} char - Символ, используемый для разделения полей.
 * @returns Строка
 */
export function fullFieldsToChar(
  fullFields: Array<string>,
  char: string
): string {
  if (fullFields) {
    let shor = fullFields.length > 0 && fullFields[0];
    for (let i = 1; i < fullFields.length; i++) {
      shor +=
        fullFields[i] && fullFields[i].length
          ? ` ${char} ${fullFields[i].toUpperCase()}`
          : '';
    }
    return shor;
  }
}

/**
 * Он генерирует случайную строку символов, которая выглядит как UUID.
 * @returns Случайная строка символов
 */
export function uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

/**
 * Он принимает два массива и возвращает новый массив с элементами второго массива в том же порядке, что и первый массив.
 * @param initArr - Исходный массив
 * @param arr - Массив для сортировки.
 * @returns [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 */
export function organizeArr(initArr, arr) {
  const outputdArr = [];
  let mathArr = [];
  const otherArr = [];

  arr.forEach(item => {
    const index = initArr.indexOf(item);
    if (index !== -1) {
      mathArr.push({
        index,
        value: item
      });
    } else {
      otherArr.push(item);
    }
  });
  mathArr = mathArr.sort((a, b) => {
    const a_value = a.index;
    const b_value = b.index;

    if (a_value > b_value) {
      return 1;
    } else if (a_value < b_value) {
      return -1;
    } else {
      return 0;
    }
  });
  mathArr.forEach(item => {
    outputdArr.push(item.value);
  });
  otherArr.forEach(item => {
    outputdArr.push(item);
  });
  return outputdArr;
}

/**
 * Он принимает значение и максимальное значение и возвращает процент значения от максимального
 * @param val - значение, которое вы хотите преобразовать в проценты
 * @param max - максимальное значение индикатора выполнения
 * @returns Процент значения по отношению к максимальному значению.
 */
export const inPercents = (val, max): number  => {
  if (!max) {
    return 0;
  }
  return (val * 100) / max;
}

export const specialFormatMonth = (month: string): string => {
  return month.length === 1 ? '0' + month : month;
}

export const specialFormatYear = (year: string): string => {
  return year.slice(2);
}

export const specialFormatDay = (day: string): string => {
  return day.length === 1 ? '0' + day : day;

}
