import { ElementRef, Injectable } from "@angular/core";
import { RoomComponent } from "app/+conference/pages/room/room.component";
import { VIDEO_CALL_CONTAINER_ID_PREFIX } from "@itorum/models";
// import { JanusService } from "app/common/services/janus.service";

@Injectable({
  providedIn: "root"
})
export class DetachModeService {
  private isDetachModeEnabled = false;
  private instance: RoomComponent;


  constructor(
    // private janusService: JanusService
  ) {
  }

  activateDetachMode() {
    this.isDetachModeEnabled = true;
  }

  deactivateDetachMode() {
    this.isDetachModeEnabled = false;
  }

  get getDetachModeStatus() {
    return this.isDetachModeEnabled;
  }

  saveInstance(instance: any) {
    this.instance = instance;
    console.log("saveInstance =>", this.instance);
  }

  getInstance() {
    return this.instance;
  }

  dropInstance() {
    this.instance = null;
  }

  /**
   * При возвращении в комнату, если на К-в-К идут трансляции видео, их нужно переподключить к тегам в комнате
   */
  reFeedCurrentPublishers(publishersContainer: ElementRef<HTMLDivElement>): Promise<Map<number, {to: HTMLVideoElement, from: HTMLVideoElement}>> {
    console.log("reFeedCurrentPublishers this.instance.publishers =>", this.instance.publishers);
    console.log("reFeedCurrentPublishers this.instance.publishersComponent.dynamicElements =>", this.instance.publishersComponent.dynamicElements);
    console.log("reFeedCurrentPublishers publishersContainer =>", publishersContainer);
    console.log("reFeedCurrentPublishers publishersContainer.nativeElement.childElementCount =>", publishersContainer.nativeElement.childElementCount);

    const instanceDynamicElements = this.instance.publishersComponent.dynamicElements;

    const htmlCollection: HTMLCollection = publishersContainer.nativeElement.children;

    const result = new Map();
    const retPromise = new Promise<Map<number, {to: HTMLVideoElement, from: HTMLVideoElement}>>((resolve, reject) => {
      console.log('reFeedCurrentPublishers promise resolved');
      return resolve(result);
    });

    try {

      for (let i = 0; i < publishersContainer.nativeElement.childElementCount; i++) {
        const fromTag: HTMLVideoElement = htmlCollection.item(i) as HTMLVideoElement;
        console.log("reFeedCurrentPublishers fromTag =>", fromTag);
        const dirtyId: string = fromTag.getAttribute("id");
        console.log("reFeedCurrentPublishers dirtyId =>", dirtyId);
        const id: number = +dirtyId.split(VIDEO_CALL_CONTAINER_ID_PREFIX)[1];
        console.log("reFeedCurrentPublishers id =>", id);
        const toTag = instanceDynamicElements.get(id).instance.videoTag.nativeElement;
        console.log("reFeedCurrentPublishers toTag =>", toTag);
        // this.janusService.reattachMediaStreamFormTo(toTag, fromTag);
        result.set(id, {to: toTag, from: fromTag});
      }

    } catch (e) {
      console.error("reFeedCurrentPublishers restore fuckup =>", e);
    }
    // console.log('reFeedCurrentPublishers this.instance.publishersComponent.videoTags =>', this.instance.publishersComponent.videoTags);

    console.log("reFeedCurrentPublishers result =>", result);
    return retPromise;
  }
}
