/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { ActionGetreportBody } from "../model/actionGetreportBody";
import { ActionGetreportdataBody } from "../model/actionGetreportdataBody";
import { DefectGetreportBody } from "../model/defectGetreportBody";
import { DefectGetreportdataBody } from "../model/defectGetreportdataBody";
import { JobGetreportBody } from "../model/jobGetreportBody";
import { JobGetreportdataBody } from "../model/jobGetreportdataBody";
import { ReportReporttemplateBody } from "../model/reportReporttemplateBody";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";


@Injectable()
export class ReportService {

  protected basePath = "https://dev.itorummr.com";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Get action report data
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportActionGetReportDataPost(body?: ActionGetreportdataBody, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiReportActionGetReportDataPost(body?: ActionGetreportdataBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiReportActionGetReportDataPost(body?: ActionGetreportdataBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiReportActionGetReportDataPost(body?: ActionGetreportdataBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/api/report/action/get-report-data`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get action report file
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportActionGetReportPost(body?: ActionGetreportBody, observe?: "body", reportProgress?: boolean): Observable<string>;
  public apiReportActionGetReportPost(body?: ActionGetreportBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<string>>;
  public apiReportActionGetReportPost(body?: ActionGetreportBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<string>>;
  public apiReportActionGetReportPost(body?: ActionGetreportBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/pdf"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<string>("post", `${this.basePath}/api/report/action/get-report`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get defect report data
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportDefectGetReportDataPost(body?: DefectGetreportdataBody, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiReportDefectGetReportDataPost(body?: DefectGetreportdataBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiReportDefectGetReportDataPost(body?: DefectGetreportdataBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiReportDefectGetReportDataPost(body?: DefectGetreportdataBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/api/report/defect/get-report-data`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get defect report file
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportDefectGetReportPost(body?: DefectGetreportBody, observe?: "body", reportProgress?: boolean): Observable<Blob>;
  public apiReportDefectGetReportPost(body?: DefectGetreportBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public apiReportDefectGetReportPost(body?: DefectGetreportBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public apiReportDefectGetReportPost(body?: DefectGetreportBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/octet-stream'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
      headers = headers.set('Content-Disposition', 'attachment;filename=defects-template.docx');
    }
    // to determine the Content-Type header
    const consumes: string[] = [
      // "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request("post", `${this.basePath}/api/report/defect/get-report`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: "blob"
      }
    );
  }

  /**
   * Get job report data
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportJobGetReportDataPost(body?: JobGetreportdataBody, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiReportJobGetReportDataPost(body?: JobGetreportdataBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiReportJobGetReportDataPost(body?: JobGetreportdataBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiReportJobGetReportDataPost(body?: JobGetreportdataBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/api/report/job/get-report-data`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get job report file
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportJobGetReportPost(body?: JobGetreportBody, observe?: "body", reportProgress?: boolean): Observable<string>;
  public apiReportJobGetReportPost(body?: JobGetreportBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<string>>;
  public apiReportJobGetReportPost(body?: JobGetreportBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<string>>;
  public apiReportJobGetReportPost(body?: JobGetreportBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/pdf"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<string>("post", `${this.basePath}/api/report/job/get-report`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set default template report
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiReportReportTemplatePost(body?: ReportReporttemplateBody, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiReportReportTemplatePost(body?: ReportReporttemplateBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiReportReportTemplatePost(body?: ReportReporttemplateBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiReportReportTemplatePost(body?: ReportReporttemplateBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/api/report/report-template`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
