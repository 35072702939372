export interface IGalleryMedia {
  id: number,
  src: string;
  thumbSrc: string | ArrayBuffer;
  videoLength: string;
  kind: GalleryMediaType;
  rawData: any;
  thumbRawData: any;
}

export enum GalleryMediaType {
  VIDEO = 'video',
  PHOTO = 'photo',
  UNKNOWN = 'unknown'
}
