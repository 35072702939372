/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApiStepBody {
  code?: string;
  typeId?: number;
  status?: ApiStepBody.StatusEnum;
  description?: string;
  questionId?: number;
  schemeDescription?: any;
}
export namespace ApiStepBody {
  export type StatusEnum = 'new' | 'deleted';
  export const StatusEnum = {
    New: 'new' as StatusEnum,
    Deleted: 'deleted' as StatusEnum
  };
}
