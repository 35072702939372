/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
}                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiJobsBody } from '../model/apiJobsBody';
import { InlineResponse2005 } from '../model/inlineResponse2005';
import { InlineResponse2006 } from '../model/inlineResponse2006';
import { InlineResponse2007 } from '../model/inlineResponse2007';
import { JobIdTechnikBody } from '../model/jobIdTechnikBody';
import { JobsJobIdBody } from '../model/jobsJobIdBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { JobsFindResponse } from "../model/jobsFindResponse";
import { JobsFindBody } from "../model/jobsFindBody";


@Injectable()
export class JobService {

  protected basePath = "https://dev.itorum-mr.ru";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Find jobs by filter
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsFindPost(body?: JobsFindBody, observe?: 'body', reportProgress?: boolean): Observable<JobsFindResponse>;
  public apiJobsFindPost(body?: JobsFindBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<JobsFindResponse>>;
  public apiJobsFindPost(body?: JobsFindBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<JobsFindResponse>>;
  public apiJobsFindPost(body?: JobsFindBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2007>('post',`${this.basePath}/api/jobs/find`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  /**
   * Get jobs
   *
   * @param jobTypeIds job Type Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsGet(jobTypeIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
  public apiJobsGet(jobTypeIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
  public apiJobsGet(jobTypeIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
  public apiJobsGet(jobTypeIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (jobTypeIds) {
      jobTypeIds.forEach((element) => {
        queryParameters = queryParameters.append('jobTypeIds', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse2005>('get',`${this.basePath}/api/jobs`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get job
   *
   * @param jobId Job id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsJobIdGet(jobId: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2006>;
  public apiJobsJobIdGet(jobId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2006>>;
  public apiJobsJobIdGet(jobId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2006>>;
  public apiJobsJobIdGet(jobId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobId === null || jobId === undefined) {
      throw new Error('Required parameter jobId was null or undefined when calling apiJobsJobIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2006>('get',`${this.basePath}/api/jobs/${encodeURIComponent(String(jobId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update job
   *
   * @param jobId Job id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsJobIdPut(jobId: number, body?: JobsJobIdBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2006>;
  public apiJobsJobIdPut(jobId: number, body?: JobsJobIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2006>>;
  public apiJobsJobIdPut(jobId: number, body?: JobsJobIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2006>>;
  public apiJobsJobIdPut(jobId: number, body?: JobsJobIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobId === null || jobId === undefined) {
      throw new Error('Required parameter jobId was null or undefined when calling apiJobsJobIdPut.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2006>('put',`${this.basePath}/api/jobs/${encodeURIComponent(String(jobId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get job techniks
   *
   * @param jobId Job id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsJobIdTechnikGet(jobId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InlineResponse2007>>;
  public apiJobsJobIdTechnikGet(jobId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InlineResponse2007>>>;
  public apiJobsJobIdTechnikGet(jobId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InlineResponse2007>>>;
  public apiJobsJobIdTechnikGet(jobId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobId === null || jobId === undefined) {
      throw new Error('Required parameter jobId was null or undefined when calling apiJobsJobIdTechnikGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<InlineResponse2007>>('get',`${this.basePath}/api/jobs/${encodeURIComponent(String(jobId))}/technik`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set job techniks
   *
   * @param jobId Job id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsJobIdTechnikPost(jobId: number, body?: JobIdTechnikBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public apiJobsJobIdTechnikPost(jobId: number, body?: JobIdTechnikBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiJobsJobIdTechnikPost(jobId: number, body?: JobIdTechnikBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiJobsJobIdTechnikPost(jobId: number, body?: JobIdTechnikBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobId === null || jobId === undefined) {
      throw new Error('Required parameter jobId was null or undefined when calling apiJobsJobIdTechnikPost.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post',`${this.basePath}/api/jobs/${encodeURIComponent(String(jobId))}/technik`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create job
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsPost(body?: ApiJobsBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2006>;
  public apiJobsPost(body?: ApiJobsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2006>>;
  public apiJobsPost(body?: ApiJobsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2006>>;
  public apiJobsPost(body?: ApiJobsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2006>('post',`${this.basePath}/api/jobs`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Archive job
   *
   * @param jobId Job id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobsJobIdArchivePut(jobId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public apiJobsJobIdArchivePut(jobId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiJobsJobIdArchivePut(jobId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiJobsJobIdArchivePut(jobId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobId === null || jobId === undefined) {
      throw new Error('Required parameter jobId was null or undefined when calling apiJobsJobIdArchivePut.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const body = { isArchive: true };

    return this.httpClient.request<any>('put',`${this.basePath}/api/jobs/${encodeURIComponent(String(jobId))}/archive`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
