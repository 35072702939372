import { AppConfig } from '@itorum/models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment.prod';
import { Router } from "@angular/router";

@Injectable()
export class AppConfigService {
  private _appConfig: AppConfig;

  get appConfig(): AppConfig {
    return this._appConfig;
  }

  public isNipiMode() {
    return this._appConfig.client === 'NIPI';
  }

  public constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  public initAppSettings(): Promise<AppConfig> {
    if (this._appConfig) {
      return Promise.resolve(this._appConfig);
    }

    return this.http
      .get<AppConfig>('./assets/app-settings.json')
      .toPromise()
      .then(config => {
        this._appConfig = {
          ...config,
          timeZoneOffset: (new Date()).getTimezoneOffset() / 60
        };
        if (!environment.production) {
          console.log(`Got app settings`, config);
        }
        this.pathBackendHostname(config?.backendHostname);
        return config;
      })
      .catch(err => {
        console.error('Can\'t get app config');
        this.router.navigateByUrl('/oops');
        return err;
      });
  }

  public get deviationPercentage() {
    return environment.techcards.deviationPercentage;
  }

  private pathBackendHostname(backendHostname: string | undefined) {
    document.location["backendHostname"] = backendHostname;
    console.log('document.location.backendHostname =>', document.location["backendHostname"]);
  }
}
