import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IJanusUser } from '@src/app/+conference/models/janus-user';
import { JanusService } from '@src/app/common/services/janus.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 *
 * @Deprecated
 */
@Component({
  selector: 'itorum-intent-add-member',
  templateUrl: './intent-add-member.component.html',
  styleUrls: ['./intent-add-member.component.scss']
})
export class IntentAddmemberComponent implements OnInit, OnDestroy {

  @Input() user: IJanusUser;


  isInviteSended = false;
  isInviteAccepted = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private janusService: JanusService
  ) { }

  /**
   * Функция ngOnInit подписывается на наблюдаемые объекты onInviteRejected$ и onInviteAccepted$ из службы Janus и
   * соответствующим образом обновляет состояние компонента.
   */
  ngOnInit(): void {
    this.janusService.onInviteRejected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IJanusUser) => {
        if (!user) {
          return;
        }
        if (user.id === this.user.id) {
          this.isInviteSended = false;
        }
      });

    this.janusService.onInviteAccepted$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IJanusUser) => {
        if (!user) {
          console.error('no user on onInviteAccepted$');
          return;
        }
        if (user.id === this.user.id) {
          this.isInviteAccepted = true;
        }
      });
  }

  /**
   * Функция ngOnDestroy отправляет сигнал на уничтожение субъекта.
   */
  ngOnDestroy() {
    this.destroy$.next(true);
  }

  /**
   * Функция отправляет пользователю приглашение присоединиться к текущей комнате и устанавливает флаг, указывающий, что
   * приглашение отправлено.
   */
  sendInvite() {
    this.isInviteSended = true;
    this.janusService.inviteUserToCurrentRoom(this.user);
  }
}
