/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InlineResponse20065Data { 
    id?: number;
    testingId?: number;
    type?: string;
    question?: string;
    createdAt?: string;
    updatedAt?: string;
    format?: InlineResponse20065Data.FormatEnum;
    userScore?: number;
}
export namespace InlineResponse20065Data {
    export type FormatEnum = 'full' | 'partial' | 'reducing';
    export const FormatEnum = {
        Full: 'full' as FormatEnum,
        Partial: 'partial' as FormatEnum,
        Reducing: 'reducing' as FormatEnum
    };
}