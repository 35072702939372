/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface QuestionQuestionIdBody { 
    type?: QuestionQuestionIdBody.TypeEnum;
    question?: string;
    testingId?: number;
    format?: QuestionQuestionIdBody.FormatEnum;
}
export namespace QuestionQuestionIdBody {
    export type TypeEnum = 'with_one' | 'with_many' | 'with_img' | 'with_order_num' | 'with_custom';
    export const TypeEnum = {
        One: 'with_one' as TypeEnum,
        Many: 'with_many' as TypeEnum,
        Img: 'with_img' as TypeEnum,
        OrderNum: 'with_order_num' as TypeEnum,
        Custom: 'with_custom' as TypeEnum
    };
    export type FormatEnum = 'full' | 'partial' | 'reducing';
    export const FormatEnum = {
        Full: 'full' as FormatEnum,
        Partial: 'partial' as FormatEnum,
        Reducing: 'reducing' as FormatEnum
    };
}