/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { ApiUserdocsBody } from "../model/apiUserdocsBody";
import { FolderIdBody } from "../model/folderIdBody";
import { InlineResponse20070 } from "../model/inlineResponse20070";
import { InlineResponse20071 } from "../model/inlineResponse20071";
import { InlineResponse20072 } from "../model/inlineResponse20072";
import { InlineResponse20073 } from "../model/inlineResponse20073";
import { UserdocsFoldersBody } from "../model/userdocsFoldersBody";
import { UserdocsShareBody } from "../model/userdocsShareBody";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";


@Injectable()
export class UserDocsDiskService {

  protected basePath = "https://dev.itorummr.com";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Delete folder and all files
   *
   * @param id User folder id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsFolderIdDelete(id: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiUserDocsFolderIdDelete(id: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiUserDocsFolderIdDelete(id: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiUserDocsFolderIdDelete(id: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error("Required parameter id was null or undefined when calling apiUserDocsFolderIdDelete.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("delete", `${this.basePath}/api/user-docs/folder/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update folder
   *
   * @param id User folder id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsFolderIdPut(id: number, body?: FolderIdBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20073>;
  public apiUserDocsFolderIdPut(id: number, body?: FolderIdBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20073>>;
  public apiUserDocsFolderIdPut(id: number, body?: FolderIdBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20073>>;
  public apiUserDocsFolderIdPut(id: number, body?: FolderIdBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error("Required parameter id was null or undefined when calling apiUserDocsFolderIdPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20073>("put", `${this.basePath}/api/user-docs/folder/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create user folder
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsFoldersPost(body?: UserdocsFoldersBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20073>;
  public apiUserDocsFoldersPost(body?: UserdocsFoldersBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20073>>;
  public apiUserDocsFoldersPost(body?: UserdocsFoldersBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20073>>;
  public apiUserDocsFoldersPost(body?: UserdocsFoldersBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20073>("post", `${this.basePath}/api/user-docs/folders`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get all user docs by user
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsGet(observe?: "body", reportProgress?: boolean): Observable<InlineResponse20070>;
  public apiUserDocsGet(observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20070>>;
  public apiUserDocsGet(observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20070>>;
  public apiUserDocsGet(observe: any = "body", reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20070>("get", `${this.basePath}/api/user-docs`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create user docs
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsPost(body?: ApiUserdocsBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20071>;
  public apiUserDocsPost(body?: ApiUserdocsBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20071>>;
  public apiUserDocsPost(body?: ApiUserdocsBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20071>>;
  public apiUserDocsPost(body?: ApiUserdocsBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20071>("post", `${this.basePath}/api/user-docs`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Share user docs
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsSharePost(body?: UserdocsShareBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20072>;
  public apiUserDocsSharePost(body?: UserdocsShareBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20072>>;
  public apiUserDocsSharePost(body?: UserdocsShareBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20072>>;
  public apiUserDocsSharePost(body?: UserdocsShareBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20072>("post", `${this.basePath}/api/user-docs/share`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete
   *
   * @param userDocId User doc id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsUserDocIdDelete(userDocId: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiUserDocsUserDocIdDelete(userDocId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiUserDocsUserDocIdDelete(userDocId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiUserDocsUserDocIdDelete(userDocId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (userDocId === null || userDocId === undefined) {
      throw new Error("Required parameter userDocId was null or undefined when calling apiUserDocsUserDocIdDelete.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("delete", `${this.basePath}/api/user-docs/${encodeURIComponent(String(userDocId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user doc by id
   *
   * @param userDocId User doc id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiUserDocsUserDocIdGet(userDocId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20071>;
  public apiUserDocsUserDocIdGet(userDocId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20071>>;
  public apiUserDocsUserDocIdGet(userDocId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20071>>;
  public apiUserDocsUserDocIdGet(userDocId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (userDocId === null || userDocId === undefined) {
      throw new Error("Required parameter userDocId was null or undefined when calling apiUserDocsUserDocIdGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20071>("get", `${this.basePath}/api/user-docs/${encodeURIComponent(String(userDocId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
