/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TestingQuestionBody { 
    type?: string;
    question?: string;
    testingId?: number;
    format?: TestingQuestionBody.FormatEnum;
}
export namespace TestingQuestionBody {
    export type FormatEnum = 'full' | 'partial' | 'reducing';
    export const FormatEnum = {
        Full: 'full' as FormatEnum,
        Partial: 'partial' as FormatEnum,
        Reducing: 'reducing' as FormatEnum
    };
}