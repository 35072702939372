/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InlineResponse20035DataStepInfo } from './inlineResponse20035DataStepInfo';
import { InlineResponse20035DataStepTimes } from './inlineResponse20035DataStepTimes';

export interface InlineResponse20035Data { 
    id?: number;
    code?: string;
    typeId?: number;
    status?: InlineResponse20035Data.StatusEnum;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    orderNum?: number;
    questionId?: number;
    stepTimes?: InlineResponse20035DataStepTimes;
    stepInfo?: InlineResponse20035DataStepInfo;
    schemeDescription?: any;
}
export namespace InlineResponse20035Data {
    export type StatusEnum = 'new' | 'deleted';
    export const StatusEnum = {
        New: 'new' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}