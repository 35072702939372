/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InlineResponse20064Answers } from './inlineResponse20064Answers';
import { InlineResponse20064Result } from './inlineResponse20064Result';

export interface InlineResponse20064Questions { 
    id?: number;
    name?: string;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    actionTemplateId?: number;
    actionId?: number;
    format?: InlineResponse20064Questions.FormatEnum;
    userScore?: number;
    result?: InlineResponse20064Result;
    answers?: Array<InlineResponse20064Answers>;
}
export namespace InlineResponse20064Questions {
    export type FormatEnum = 'full' | 'partial' | 'reducing';
    export const FormatEnum = {
        Full: 'full' as FormatEnum,
        Partial: 'partial' as FormatEnum,
        Reducing: 'reducing' as FormatEnum
    };
}