import { ViewContainerRef, TemplateRef } from '@angular/core';
import { ArrayToggle } from "@itorum/models";
import { ItrRowDefDirective } from './directives/itr-row-def.directive';

export interface ItrCellOutletMultiRowContext<T> {
  $implicit?: T;
  count?: number;
  dataIndex?: number;
  even?: boolean;
  first?: boolean;
  last?: boolean;
  odd?: boolean;
  renderIndex?: number;
}

export interface ItrCellOutletRowContext<T> {
  $implicit?: T;
  count?: number;
  even?: boolean;
  first?: boolean;
  index?: number;
  last?: boolean;
  odd?: boolean;
}

export interface RowOutlet {
  viewContainer: ViewContainerRef;
}

export interface RenderRow<T> {
  data: T;
  dataIndex: number;
  rowDef: ItrRowDefDirective<T>;
}

export interface RowContext<T>
  extends ItrCellOutletMultiRowContext<T>,
    ItrCellOutletRowContext<T> {}

export interface IchangeFieldFilterParams {
  field: string;
  arr: ArrayToggle<any[]>;
  multi: boolean;
}

export interface ItrCellConfig {
  field: {
    code: string;
    index: number;
    label: string;
    value: string;
    filter: string[];
  };
  width: number;
  isVisible: boolean;
  isBigFont: boolean;
  breakWord: boolean;
  breakWordSymbol: boolean;
}

export interface ItrCellConfigHome {
  field: {
    code: string;
    index: number;
    label: string;
    value: string;
  };
  width: number;
  isVisible: boolean;
  isBigFont: boolean;
  breakWord: boolean;
  breakWordSymbol: boolean;
}

export interface ItrCellSort {
  field: string;
  sortType: string;
}

export interface ItrTableConfig {
  cells: ItrCellConfig[];
  sort: ItrCellSort;
  itemHeight: number;
  filter: string;
}

export interface ItrTableConfigHome {
  cells: ItrCellConfigHome[];
  itemHeight: number;
  sort: ItrCellSort;
}

export interface CellDef {
  template: TemplateRef<any>;
}

export enum ERowType {
  ROW,
  HEADER,
  FOOTER
}
