import { Injectable } from '@angular/core';
import { RoomStateStore } from './room-state.store';
import { IControlChatMessageBody, IFeed } from "@itorum/models";

@Injectable({ providedIn: 'root' })
export class RoomStateService {

  constructor(private roomStateStore: RoomStateStore) {
  }

  /**
   * Функция обновляет хранилище состояния комнаты логическим значением, указывающим, находится ли комната в широкоэкранном
   * режиме или нет.
   * @param {boolean} mode - логическое значение, указывающее, следует ли включить или отключить широкоэкранный режим.
   */
  doWidescreen(mode: boolean) {
    this.roomStateStore.update((store) => {
      return {
        ...store,
        isWidescreen: mode
      }
    })
  }

  /**
   * Эта функция обновляет ленту в хранилище состояния комнаты, отфильтровывая старую ленту и добавляя новую.
   * @param {IFeed} feed - Параметр `feed` представляет собой объект типа `IFeed`. Он используется в качестве аргумента в
   * функции updateFeeds для обновления массива каналов в объекте roomStateStore. Функция отфильтровывает существующий
   * канал с тем же `rfid`, что и
   */
  updateFeeds(feed: IFeed) {
    console.log('try to updateFeeds =>', feed);
    this.roomStateStore.update((state) => {
      const match: IFeed[] = state.feeds.filter(f => f.rfid !== feed.rfid)
      return {
        ...state,
        feeds: [
          ...match,
          feed
        ]
      }
    })
  }

  /**
   * Эта функция удаляет фиды из хранилища состояния комнаты на основе заданного массива фидов.
   * @param {IFeed[]} feeds - Feeds — это массив объектов типа IFeed. Используется как параметр в функции `deleteFeeds`.
   */
  deleteFeeds(feeds: IFeed[]) {
    this.roomStateStore.update((state) => {
      const match: IFeed[] = state.feeds.filter( f => !feeds.some(df => df.rfid === f.rfid));
      return {
        ...state,
        feeds: [
          ...match
        ]
      }

    })
  }

  removeAllFeeds() {
    console.log('removeAllFeeds invoked');
    this.roomStateStore.update((state) => {
      return {
        ...state,
        feeds: []
      }
    })
  }

  /**
   * Эта функция обновляет состояние комнаты, чтобы отобразить или скрыть рисунок.
   * @param {boolean} value - логическое значение, определяющее, показывать или скрывать рисунок.
   */
  doShowDraw(value: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        isShowDraw: value
      }
    });
  }

  /**
   * Функция обновляет состояние комнаты, чтобы указать, используется ли она в настоящее время совместно или нет.
   * @param {boolean} value - логическое значение, которое представляет, является ли состояние общим или нет.
   */
  doShareState(value: boolean) {
    this.roomStateStore.update( (state) => {
        return {
          ...state,
          isNowShared: value
        }
    })
  }

  /**
   * Эта функция обновляет свойство файла в хранилище состояния комнаты новым файлом или большим двоичным объектом.
   * @param {File | Blob | undefined} file - Параметр `file` имеет тип `File | Капля | не определено`. Это означает, что
   * это может быть либо объект File, либо объект Blob, либо объект undefined. Объекты File и Blob используются для
   * представления файлов или данных, которые можно читать асинхронно.
   */
  updateFile(file: File | Blob | undefined) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        file
      }
    })
  }

  /**
   * Эта функция обновляет свойство изображения хранилища состояния комнаты.
   * @param {string | undefined} image - Параметр image представляет собой строку или неопределенное значение,
   * представляющее новое изображение, которое необходимо обновить в состоянии комнаты. Он передается в качестве аргумента
   * в метод updateImage. Если передается строковое значение, оно представляет собой URL-адрес или путь к новому
   * изображению. Если передается undefined
   */
  updateImage(image: string | undefined) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        image
      }
    })
  }

  /**
   * Эта функция обновляет сообщения чата в хранилище состояний комнаты, добавляя новое сообщение.
   * @param {IControlChatMessageBody} msg - Параметр `msg` имеет тип `IControlChatMessageBody`, который, вероятно, является
   * интерфейсом или типом, определяющим структуру объекта сообщения чата. Эта функция используется для обновления
   * сообщений чата в roomStateStore путем добавления нового объекта msg к существующему чату.
   */
  updateChatMessages(msg: IControlChatMessageBody) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        chatMessages: [
          ...state.chatMessages,
          msg
        ]
      }
    })
  }

  /**
   * Функция очищает все сообщения чата в хранилище состояния комнаты.
   */
  cleanChatMessages() {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        chatMessages: []
      }
    })
  }


  /**
   * Эта функция обновляет URL-адрес снимка экрана запроса в хранилище состояния комнаты.
   * @param {string | undefined} url - Параметр `url` представляет собой строку или неопределенное значение, представляющее
   * URL-адрес запрошенного снимка экрана. Он используется для обновления свойства reqScreenshotUrl в объекте
   * roomStateStore.
   */
  updateReqScreenshotSrc(url: string | undefined) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        reqScreenshotUrl: url
      }
    })
  }

  /**
   * Эта функция обновляет модальное открытое состояние боковой стороны комнаты.
   * @param {boolean} isSideModalOpen - Логическое значение, указывающее, открыто ли боковое модальное окно или нет.
   */
  updateSideModalOpenState(isSideModalOpen: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        isSideModalOpen
      }
    })
  }

  /**
   * Эта функция обновляет состояние комнаты, чтобы отключить или включить звук всех издателей.
   * @param {boolean} doMute - Логическое значение, указывающее, должны ли быть отключены все издатели. Если `doMute` равно
   * `true`, все издатели будут отключены. Если `doMute` равно `false`, все издатели будут включены.
   */
  updateMuteAllPublishers(doMute: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        isAllPublishersMuted: doMute
      }
    })
  }

  /**
   * Эта функция обновляет свойство `doTerminateWorker` в `roomStateStore` с предоставленным логическим значением.
   * @param {boolean} doTerminate - Параметр doTerminate — это логическое значение, которое передается в качестве аргумента
   * функции doTerminateWorker. Он используется для обновления свойства doTerminateWorker в объекте состояния
   * roomStateStore. Если `doTerminate` равно `true`, это означает, что
   */
  doTerminateWorker(doTerminate: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        doTerminateWorker: doTerminate
      }
    })
  }

  /**
   * Эта функция обновляет состояние комнаты, чтобы указать, активен ли охранник.
   * @param {boolean} isGuardActive - Логическое значение, указывающее, активна защита или нет.
   */
  updateIsGuardActive(isGuardActive: boolean) {
    this.roomStateStore.update( (state) => {
      return {
        ...state,
        isGuardActive
      }
    })
  }

  doVideoAttach(isVideoAttached: boolean) {
    this.roomStateStore.update( (state) => {
      return {
        ...state,
        isVideoAttached
      }
    });
  }
}
