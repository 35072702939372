/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TestingGeneratefromactiontemplateBody { 
    questionType?: TestingGeneratefromactiontemplateBody.QuestionTypeEnum;
    questionCount?: number;
    actionTemplateId?: number;
    temperature?: number;
}
export namespace TestingGeneratefromactiontemplateBody {
    export type QuestionTypeEnum = 'with_one' | 'with_many' | 'with_img' | 'with_order_num' | 'with_custom';
    export const QuestionTypeEnum = {
        One: 'with_one' as QuestionTypeEnum,
        Many: 'with_many' as QuestionTypeEnum,
        Img: 'with_img' as QuestionTypeEnum,
        OrderNum: 'with_order_num' as QuestionTypeEnum,
        Custom: 'with_custom' as QuestionTypeEnum
    };
}