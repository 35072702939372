<section class="flex items-center justify-center" style="height: 100vh">
  <article class="flex items-center">
    <img src="assets/img/oops.svg" alt="oops">
    <section>
      <div class="text-5xl font-bold">Упс!</div>
      <div class="text-5xl font-bold">Что-то пошло не так...</div>
      <div class="text-3xl text-gray-lighter_minus">Сервис временно недоступен.</div>
      <div class="text-xl text-gray-lighter_minus">Попробуйте очистить кеш браузера и обновить страницу позднее.</div>
    </section>
  </article>
</section>
