import { GalleryMediaType, IGalleryMedia } from "../interfaces";

export const mapMedias = (value: any[]): IGalleryMedia[] => {
  const output: IGalleryMedia[] = [];
  value.forEach((v) => {
    output.push({
      src: v?.file_info.file_path,
      thumbSrc: v?.file_info.thumb_path,
      kind: mapKind(v?.file_info?.mimetype),
      videoLength: '',
      rawData: null,
      thumbRawData: null,
      id: v?.file_info?.id
    })
  });
  return output;
}

export const mapKind = (mimetype: string): GalleryMediaType => {
  if (!mimetype) {
    return GalleryMediaType.UNKNOWN;
  }

  if (mimetype.includes("video")) {
    return GalleryMediaType.VIDEO;
  }

  if (mimetype.includes("image")) {
    return GalleryMediaType.PHOTO;
  }

}
