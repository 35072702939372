/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InlineResponse20062Data { 
    id?: number;
    name?: string;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    actionTemplateId?: number;
    actionId?: number;
    startDate?: string;
    finishDate?: string;
    minPassScore?: number;
    userScore?: number;
    maxTestTimeSec?: number;
    status?: InlineResponse20062Data.StatusEnum;
}
export namespace InlineResponse20062Data {
    export type StatusEnum = 'pass' | 'not_pass' | 'timeout';
    export const StatusEnum = {
        Pass: 'pass' as StatusEnum,
        NotPass: 'not_pass' as StatusEnum,
        Timeout: 'timeout' as StatusEnum
    };
}