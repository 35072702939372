import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { IJanusUser } from '@src/app/+conference/models/janus-user';
// import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { ToastPackage, ToastrService } from 'ngx-toastr';
// import { JanusJS } from 'janus-gateway/npm';
// import Janus = JanusJS.Janus;
import { JanusService } from '@src/app/common/services/janus.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'itorum-toastr-do-call-dialog',
  templateUrl: './toastr-do-call-dialog.component.html',
  styleUrls: ['./toastr-do-call-dialog.component.scss'],
  // animations: [
  //   trigger('flyInOut', [
  //     state('inactive', style({ opacity: 0 })),
  //     state('active', style({ opacity: 1 })),
  //     state('removed', style({ opacity: 0 })),
  //     transition(
  //       'inactive => active',
  //       animate('{{ easeTime }}ms {{ easing }}')
  //     ),
  //     transition(
  //       'active => removed',
  //       animate('{{ easeTime }}ms {{ easing }}')
  //     )
  //   ])
  // ],
})
export class ToastrDoCallDialogComponent implements OnInit, OnDestroy {
  private destroy$: Subject<any> = new Subject();
  timeout: any;
  /** controls animation */
  // @HostBinding('@flyInOut')
  // state = {
  //   value: 'inactive',
  //   params: {
  //     easeTime: this.toastPackage.config.easeTime,
  //     easing: 'ease-in'
  //   }
  // };
  private uid: string;
  private user: IJanusUser;
  private sfutest: any;
  private callCancelation: (call) => void;
  text: string;
  title: string;
  private janusService: JanusService;
  constructor(
    private router: Router,
    protected toastrService: ToastrService,
    // private janusService: JanusService,
    public toastPackage: ToastPackage,
    protected ngZone?: NgZone
  ) {
    this.text = toastPackage.message;
    this.title = toastPackage.title;
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private runInsideAngular(func: () => any) {
    if (this.ngZone) {
      this.ngZone.run(() => func());
    } else {
      func();
    }
  }

  outsideTimeout(func: () => any, timeout: number) {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(
        () =>
          (this.timeout = setTimeout(
            () => this.runInsideAngular(func),
            timeout
          ))
      );
    } else {
      this.timeout = setTimeout(() => func(), timeout);
    }
  }

  /**
   * set janus objects to respond out
   * @param uid
   * @param user
   * @param sfutest
   * @param callCancelation {Function}
   * @param janusInstance
   */
  setData(uid, user, sfutest, callCancelation, janusInstance) {
    this.janusService = janusInstance;
    console.log('setData uid ==>', uid);
    this.uid = uid;
    this.user = user;
    this.sfutest = sfutest;
    this.callCancelation = callCancelation;

    this.janusService.onIncomingCallRejected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((answer) => {
        if (answer) {
          this.remove();
        }
      });
  }

  cancel() {
    this.callCancelation({user: this.user, uid: this.uid});
    this.remove();
  }

  /**
   * tells toastrService to remove this toast after animation time
   */
  remove() {
    // if (this.state.value === 'removed') {
    //   return;
    // }
    clearTimeout(this.timeout);
    // this.state = { ...this.state, value: 'removed' };
    this.outsideTimeout(
      () => this.toastrService.remove(this.toastPackage.toastId),
      +this.toastPackage.config.easeTime
    );
  }
}
