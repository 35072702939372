/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StepStepIdBody {
    code?: string;
    typeId?: string;
    status?: StepStepIdBody.StatusEnum;
    description?: string;
    schemeDescription?: any;
}
export namespace StepStepIdBody {
    export type StatusEnum = 'new' | 'deleted';
    export const StatusEnum = {
        New: 'new' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
