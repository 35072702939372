/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ReportReporttemplateBody { 
    reportTemplateId?: number;
    type?: ReportReporttemplateBody.TypeEnum;
}
export namespace ReportReporttemplateBody {
    export type TypeEnum = 'action' | 'step-defect' | 'job';
    export const TypeEnum = {
        Action: 'action' as TypeEnum,
        StepDefect: 'step-defect' as TypeEnum,
        Job: 'job' as TypeEnum
    };
}